import { ApplicationController } from './application_controller';

export default class extends ApplicationController {
  static targets = [ 'deletemodal', 'deletereason', 'confimbutton' ];

  static values = {
    isModalOpen: { type: Boolean, default: false }
  } 
  
  initialize() {
    this.isModalOpen = false;
  }

  toggle() {
    this.isModalOpen = !this.isModalOpen;
    this.deletemodalTarget.classList.toggle("tw--hidden");
  }

  selected(event) {
    if (event.target.id === "reason_other_please_specify") {      
      this.deletereasonTarget.value = "";
      this.deletereasonTarget.classList.remove("tw--hidden");
      this.deletereasonTarget.focus();
    } else {
      this.deletereasonTarget.value = event.target.value;
      this.deletereasonTarget.classList.add("tw--hidden");
    }
  }

  check_reason() {
    if (this.deletereasonTarget.value === "") {
      this.confimbuttonTarget.disabled = true;
    } else {
      this.confimbuttonTarget.disabled = false;
    }
  }
}