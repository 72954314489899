import { ApplicationController } from './application_controller';

export default class extends ApplicationController {
  static targets = [ 'table', 'source', 'filterable', 'noResults' ];

  filter(event) {
    let lowerCaseFilterTerm = this.sourceTarget.value.toLowerCase()
    let visibleCount = 0
   
    this.filterableTargets.forEach((el) => {
      let filterableRequestKey =  el.querySelector('[data-filter-request-key]').dataset.filterRequestKey.toLowerCase()
      let filterableRedirectKey =  el.querySelector('[data-filter-redirect-key]').dataset.filterRedirectKey.toLowerCase()

      el.classList.toggle('tw--hidden', !filterableRequestKey.includes(lowerCaseFilterTerm) && !filterableRedirectKey.includes(lowerCaseFilterTerm))

      if (!el.classList.contains('tw--hidden')) {
        visibleCount++
      }
    })

    this.tableTarget.classList.toggle('tw--hidden', visibleCount == 0)
    this.noResultsTarget.classList.toggle('tw--hidden', visibleCount > 0)
   
  }
}
